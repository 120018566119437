import Noty from 'noty';

Noty.overrideDefaults({
  layout   : 'topRight',
  theme    : 'sunset',
  closeWith: ['click', 'button'],
});

window.notify = function(text, type) {
  new Noty({
    text: text,
    type: type,
    timeout: 2000,
    progressBar: true,
  }).show();
}
