document.addEventListener('turbolinks:load', () => {

  // Show spinner inside the submit button when a form with class .auth-form is submitted
  $(".auth-form").on("submit", function (e) {
    // Do not show loader if form is not in valid state
    if ($(this).valid() === false) { return; }

    // Spinning icon from font awesome
    const loadingHtmlBtn = `
        <i class="fa-solid fa-circle-notch fa-spin"></i>
      `;
    const submitBtn = $(this).find('button[type="submit"]'); // Find form submit button
    submitBtn.attr("disabled", "disabled"); // Disable it
    submitBtn.attr("data-text", submitBtn.text()); // Save original text in data-text attribute
    submitBtn.html(loadingHtmlBtn); // Set inner html of button to the spinning icon
  });

  // SHOW LOADING SPINNER AND MESSAGE WHEN SUBMITTING FORM
  // Apply to any form with the class 'loading-on-submit'
  $("form.loading-on-submit").on("submit", function (e) {
    const form = $(this);

    // Get the loading text from data attribute or default to 'Loading...'
    const loadingText = form.data('loading-text') || 'Loading...';

    // HTML for the loading state
    const loadingHtmlBtn = `
      <i class="fa-solid fa-circle-notch fa-spin"></i>
      <span class="ml-2">${loadingText}</span>
    `;

    // Find the submit button(s) within the form
    const submitBtn = form.find('button[type="submit"], input[type="submit"], button:not([type])');

    // Disable the submit button(s) and set the loading HTML or value
    submitBtn.each(function() {
      const btn = $(this);
      btn.attr("disabled", "disabled");

      if (btn.is('button')) {
        btn.html(loadingHtmlBtn);
      } else if (btn.is('input')) {
        btn.val(loadingText);
      }
    });
  });

});
