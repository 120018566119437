import algoliasearch from 'algoliasearch/lite';
import instantsearch from 'instantsearch.js';
import { searchBox, hits, configure } from 'instantsearch.js/es/widgets';

// console.log('Algolia search script loaded (TAILWIND)');

// Function to handle pressing enter on a search result
const returnKeyAction = (event, searchInputSelector, submitOnEnter) => {
    var results_container = $(searchInputSelector).closest('.searchbox').find('.algolia-results-container ol');
    const searchInput = document.querySelector(`${searchInputSelector} .ais-SearchBox-input`);
    const query = searchInput.value; // Capture the input value

    if (event.which == 13) { // Enter key is hit
        // console.log('Enter key pressed');
        event.preventDefault();
        // If there's an active search result, click it
        if ($(results_container).find('li a.active').length > 0) {
            $(results_container).find('li a.active')[0].click();
        } else if (query.length > 0 && submitOnEnter) {
            // If no result is selected and submitOnEnter is true, navigate to the search page
            window.location.href = `/search?q=${encodeURIComponent(query)}`;
        }
    }
};

// Function to handle keyboard navigation within search results
const moveActiveItem = (event, itemSelector) => {
    var results_container_arrary = $(itemSelector).closest('.searchbox').find('.algolia-results-container ol');
    var results_container = results_container_arrary.find('li');

    // UP ARROW
    if (event.keyCode == 38) {
        event.preventDefault();
        // console.log('Up arrow key pressed');
        if ($(results_container).find('li a:focus').length > 0) {
            // console.log('Item focused, moving up');
            $(results_container).find('li a:focus').blur().parent().prev().find('a').addClass('active');
        }
        else if (results_container.find('a.active').length == 0) {
            // console.log('No active item, activating last item');
            $(results_container).last().find('a').addClass('active');
        }
        else if ($(results_container).find('a.active')[0] == $(results_container).first().find('a')[0]) {
            // console.log('First item active, wrapping to last item');
            $(results_container).first().find('a').removeClass('active');
            $(results_container).last().find('a').addClass('active');
        }
        else {
            // console.log('Moving to previous item');
            var current_item = results_container.find('a.active');
            current_item.removeClass('active');
            var index = results_container.index(current_item.parent());
            $(results_container[index - 1]).find('a').addClass('active');
        }
    }
    // DOWN ARROW
    else if (event.keyCode == 40) {
        event.preventDefault();
        // console.log('Down arrow key pressed');
        if ($(results_container).find('li a:focus').length > 0) {
            // console.log('Item focused, moving down');
            $(results_container).find('li a:focus').blur().parent().next().find('a').addClass('active');
        }
        else if (results_container.find('a.active').length == 0) {
            // console.log('No active item, activating first item');
            $(results_container).first().find('a').addClass('active');
        }
        else if ($(results_container).find('a.active')[0] == $(results_container).last().find('a')[0]) {
            // console.log('Last item active, wrapping to first item');
            results_container.find('a.active').removeClass('active');
            $(results_container).first().find('a').addClass('active');
        } else {
            // console.log('Moving to next item');
            var current_item = results_container.find('a.active');
            current_item.removeClass('active');
            var index = results_container.index(current_item.parent());
            $(results_container[index + 1]).find('a').addClass('active');
        }
    }
};

/**
 * Initializes Algolia search with a search box and hits widget.
 *
 * @param {string} appId - Algolia application ID.
 * @param {string} apiKey - Algolia search-only API key.
 * @param {string} containerId - A unique identifier for the search box and results container (used for both desktop and mobile versions).
 * @param {string} indexName - The name of the Algolia index to search.
 * @param {string} template - The HTML template for displaying each search result (hit).
 * @param {function} onClick - A callback function that executes when a search result is clicked, typically used for navigation.
 * @param {string} [placeholderText='Search...'] - Placeholder text for the search input field.
 * @param {string} [noResultsText='No results for <q>{{ query }}</q>'] - Message to display when no search results are found.
 * @param {string} [customInputClasses=''] - Additional CSS classes to add to the search input for customization.
 * @param {boolean} [submitOnEnter=true] - Determines if hitting "Enter" with no highlighted item should perform the search.
 */
export const createAlgoliaSearch = (appId, apiKey, containerId, indexName, template, onClick, placeholderText = 'Search...', noResultsText = 'No results for <q>{{ query }}</q>', customInputClasses = '', submitOnEnter = true) => {
    // console.log(`Initializing Algolia search for ${containerId}`);

    const searchClient = algoliasearch(appId, apiKey);

    const search = instantsearch({
        indexName,
        searchClient,
        searchFunction(helper) {
            if (helper.state.query === '') {
                return;
            }
            helper.search();
        },
    });

    const searchWidgets = [
        configure({
            hitsPerPage: 5,
        }),
        // Add the search box widget
        searchBox({
            container: `#${containerId}-search-box`,
            showSubmit: false, // This will hide the submit button
            showReset: false,
            showLoadingIndicator: false,
            placeholder: placeholderText,
            cssClasses: {
                form: 'collection-search', // Adds custom class to the form
                // Adds custom class to the input element
                input: customInputClasses, // Use custom classes passed in
            },
            // Other configurations...
        }),
        // Add the hits widget
        hits({
            container: `#${containerId}-hits`,
            // cssClasses: {
            //     root: 'my-custom-class', // Adds custom class to the root div of the hits widget
            // },
            templates: {
                item: template,
                empty: (data) => `
                    <div class="py-4">
                        <p class="text-gray-500">${noResultsText.replace('{{ query }}', data.query)}</p>
                    </div>
                    `, // Dynamically use noResultsText here
            },
            // This will transform (filter) the hits before rendering
            transformItems(items) {
                return items.map(hit => {
                    // Filter full match level and fullyHighlighted for URLs
                    if (hit._highlightResult && hit._highlightResult.urls) {
                        hit._highlightResult.urls = hit._highlightResult.urls.filter(url => url.matchLevel === 'full' && url.fullyHighlighted);
                    }

                    return hit; // Return the transformed hit
                });
            }
        }),
        // Add other widgets here if necessary
    ];

    // Add the widgets to the search instance
    search.addWidgets(searchWidgets);

    // Start the search
    search.start();

    // Function to hide the hits/results container
    function hideHitsContainer() {
        // console.log(`Hiding hits container for ${containerId}`);
        $(`#${containerId}-hits`).hide();
    }

    function showHitsContainer() {
        // console.log(`Showing hits container for ${containerId}`);
        $(`#${containerId}-hits`).show();
    }

    // Event listener to show the hits container when the search input is focused
    const searchInput = document.querySelector(`#${containerId}-search-box .ais-SearchBox-input`);
    searchInput.addEventListener('focus', () => {
        showHitsContainer();
    });

    // Function to handle showing or hiding the hits container based on input content.
    // If input is empty, call hideHitsContainer to hide the search results.
    // Otherwise ensure the results are visible.
    function handleInputVisibility() {
        if (searchInput.value.length === 0) {
            hideHitsContainer();
        } else {
            showHitsContainer();
        }
    }

    // Attach the focus event listener to the search input
    searchInput.addEventListener('focus', handleInputVisibility);
    // Attach the input event listener to the search input
    searchInput.addEventListener('input', handleInputVisibility);

    // Attach the click event listener ONLY AFTER the search renders
    search.on('render', () => {
        const hitsContainer = document.querySelector(`#${containerId}-hits`);
        const searchInput = document.querySelector(`#${containerId}-search-box .ais-SearchBox-input`);

        // Remove existing event listeners before attaching new ones
        $(document).off('keypress');
        $(document).off('keydown');

        hitsContainer.addEventListener('click', (event) => {
            event.preventDefault();
            const hit = event.target.closest('.algolia-result');
            if (hit) {
                // console.log('Hit clicked, navigating to:', hit.dataset.slug);
                onClick(hit.dataset);
                hideHitsContainer();
            }
        });

        // Hide the hits container when clicking outside of it
        document.addEventListener('click', (event) => {
            if (!searchInput.contains(event.target) && !hitsContainer.contains(event.target)) {
                hideHitsContainer();
            }
        });

        // Add keypress and keydown event listeners
        $(document).on('keypress', (e) => {
            if ($(e.target).closest(`#${containerId}-search-box`).length) {
                // console.log('keypress detected inside search box:', e.key);
                // On enter, handle the action and pass submitOnEnter
                returnKeyAction(e, `#${containerId}-search-box`, submitOnEnter);
            }
        });

        $(document).on('keydown', (e) => {
            if ($(e.target).closest(`#${containerId}-search-box`).length) {
                // console.log('keydown detected inside search box:', e.key);
                moveActiveItem(e, `#${containerId}-search-box`);
            }
        });
    });
};
