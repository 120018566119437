import cytoscape from 'cytoscape';

document.addEventListener('turbolinks:load', function() {
    const cyContainer = document.getElementById('cy');
    if (cyContainer) {
        // Retrieve the collection ID from the data attribute
        const collectionId = cyContainer.dataset.collectionId;

        fetch(`/api/collections/${collectionId}`)
            .then(response => response.json())
            .then(data => {


                // First, map over collection_links to create node elements
                const nodeElements = data.collection_links.map(link => ({
                    data: {
                        id: link.id.toString(),
                        label: link.slug,
                        color: link.color,
                        border_color: link.shadow_color,
                        icon: link.icon_path, // Adjust the path if necessary
                        width: link.node_width + 'px', // Use the dynamic width from the API
                        text_max_width: link.node_width - 10 + 'px'
                    },
                    position: { // You'll need to determine the positions for your nodes
                        x: Math.random() * 800, // Placeholder for x position
                        y: Math.random() * 600  // Placeholder for y position
                    }
                }));

                // Function to check for reciprocal links
                function isReciprocalLink(sourceId, targetId, allLinks) {
                    const sourceLink = allLinks.find(link => link.id === sourceId);
                    const targetLink = allLinks.find(link => link.id === targetId);
                    return sourceLink && targetLink &&
                        sourceLink.points_to.includes(targetId) &&
                        targetLink.points_to.includes(sourceId);
                }

                // Then, map over collection_links to create edge elements
                const edgeElements = data.collection_links.flatMap(link => {
                    return link.points_to.map(targetId => {
                        const isReciprocal = isReciprocalLink(link.id, targetId, data.collection_links);
                        console.log("isReciprocal is " + isReciprocal + " for " + link.id.toString() + " to " + targetId.toString());
                        return {
                            data: {
                                id: `e${link.id}-${targetId}`,
                                source: link.id.toString(),
                                target: targetId.toString(),
                                color: link.color, // Color of the edge is the same as the source node
                                reciprocal: isReciprocal // Add a flag to mark reciprocal links
                            }
                        };
                    });
                });

                // Combine the node elements and edge elements
                const cyElements = [...nodeElements, ...edgeElements];

                // Initialize Cytoscape with cyElements
                const cy = cytoscape({
                    container: cyContainer,
                    elements: cyElements,
                    style: [
                        // Style for nodes
                        {
                            selector: 'node',
                            style: {
                                // Shape and border styles
                                'shape': 'round-rectangle', // Set the node shape to a rounded rectangle
                                'border-color': 'data(border_color)',
                                'border-width': 3,
                                'border-style': 'solid',
                                // Label and text styles
                                'label': 'data(label)',
                                'text-valign': 'center',
                                'text-halign': 'center',
                                'color': 'data(color)', // Set text color to the 'color' data property
                                'font-weight': 'bold',
                                'text-margin-y': '10px',
                                'text-wrap': 'ellipsis',
                                'text-max-width': 'data(text_max_width)',
                                // Background image styles
                                'background-color': 'white',
                                'background-image': 'data(icon)',
                                'background-fit': 'none',  // Don't scale the image
                                'background-clip': 'none', // Ensure the image isn't clipped to the node's bounding box
                                'background-width': '24px',
                                'background-height': '24px',
                                'background-position-x': '50%', // Center the image horizontally
                                'background-position-y': '20%', // Position the image at 10% from the top of the node
                                // Overall dimensions
                                'width': 'data(width)', // Set width dynamically
                                'height': '60px',
                                'padding-top': '30px', // Add padding on the top to account for the background image
                                'padding-left': '10px',
                                'padding-right': '10px',
                                'padding-bottom': '10px',
                            }
                        },
                        // Style for regular edges
                        {
                            selector: 'edge',
                            style: {
                                'width': 2,
                                'line-color': 'data(color)', // Use the color from the edge's data
                                'line-style': 'dashed',
                                'target-arrow-color': 'data(color)', // Color of the arrowhead
                                'target-arrow-shape': 'triangle', // Shape of the arrowhead
                                'arrow-scale': 1, // Scale the size of the arrowhead
                                // Bezier curve style
                                'curve-style': 'unbundled-bezier',
                                'control-point-distances': 40, // Distance between control points for the bezier curve
                                //'control-point-weights': 0, // Between 0 and 1 to control the curve's steepness
                                //'control-point-step-size': 20, // Increase this to spread out edges more
                                // Taxi curve style
                                // 'curve-style': 'taxi',
                                // 'taxi-direction': 'auto', // or 'vertical'/'horizontal'
                                // 'taxi-turn': '50%', // where the edge turns, 50% of the distance from source to target
                                // 'taxi-turn-min-distance': 20, // the minimum distance for the edge to turn
                            }
                        },
                        // Additional style for reciprocal edges
                        {
                            selector: 'edge[?reciprocal]',
                            style: {
                                'line-style': 'solid', // Make reciprocal edges solid
                                'arrow-scale': 2, // Scale the size of the arrowhead
                            }
                        }
                    ],
                    layout: {
                        name: 'grid',
                        //rows: 4, // Number of rows in the grid (optional, if you want a fixed number of rows)
                        cols: data.grid_columns, // Use the dynamic number of columns from the API
                        condense: false,
                        avoidOverlap: true, // Prevents nodes from overlapping
                        avoidOverlapPadding: 10, // Padding around each node when avoidOverlap is used
                    }
                });

                // After the layout is complete, the event listener will trigger, applying the random
                // vertical offsets to the nodes.
                cy.on('layoutstop', function() {
                    cy.nodes().forEach(node => {
                        let yPos = node.position('y');
                        // Random offset between -10 and 10 pixels
                        const yOffset = Math.random() * 20 - 10;
                        node.position('y', yPos + yOffset);
                    });
                });

                // Run the layout. This is necessary so we can do the on('layoutstop') above to
                // add a random offset to the nodes
                cy.layout({
                    name: 'grid',
                    cols: data.grid_columns,
                    condense: false,
                    avoidOverlap: true,
                    avoidOverlapPadding: 10,
                }).run();

            })
            .catch(error => console.error('Error loading diagram data:', error));
    }
});