document.addEventListener('turbolinks:load', () => {

  // Clicking the mobile hamburger icon to hide/show the mobile menu
  $('#mobile-menu-button').click(function() {
    const $mobileMenu = $('#mobile-menu');

    if ($mobileMenu.hasClass('hidden')) {
      // If menu is initially hidden, remove 'hidden', fade in
      $mobileMenu.removeClass('hidden').addClass('transition-opacity duration-300 ease-out opacity-0').delay(10).queue(function(next){
        $(this).removeClass('opacity-0').addClass('opacity-100 visible');
        next();
      });
    } else {
      // Instantly hide the menu without transition
      $mobileMenu.removeClass('transition-opacity duration-300 ease-out opacity-100 visible').addClass('hidden');
    }

    // Toggle icons
    $('#menu-icon-bars').toggleClass('hidden');
    $('#menu-icon-xmark').toggleClass('hidden');
  });

  // Initialize navbar dropdown menus
  initDropdownMenu('#user-menu-button', '#user-menu-dropdown');
  initDropdownMenu('#most-followed-menu-button', '#most-followed-menu-dropdown');

  // Function to initialize navbar dropdown menus
  function initDropdownMenu(buttonSelector, menuSelector) {
    const $menuDropdown = $(menuSelector);

    // Toggle the dropdown menu when the button is clicked
    $(buttonSelector).click(function() {
      if ($menuDropdown.hasClass('hidden')) {
        $menuDropdown.removeClass('hidden');
        setTimeout(() => {
          $menuDropdown.removeClass('opacity-0 scale-95').addClass('opacity-100 scale-100');
        }, 10);
      } else {
        $menuDropdown.addClass('opacity-0 scale-95').removeClass('opacity-100 scale-100');
        setTimeout(() => {
          $menuDropdown.addClass('hidden');
        }, 75);
      }
    });

    // Close the dropdown by clicking outside of it
    $(document).click(function(event) {
      if (!$(event.target).closest(buttonSelector).length && !$(event.target).closest(menuSelector).length && !$menuDropdown.hasClass('hidden')) {
        $menuDropdown.addClass('opacity-0 scale-95').removeClass('opacity-100 scale-100');
        setTimeout(() => {
          $menuDropdown.addClass('hidden');
        }, 75);
      }
    });
  }

  // Clicking the search icon in the navbar on mobile to open/close the search subnav
  $('.mobile-search-toggle').on('click', function (e) {
    e.stopPropagation();
    $('#navbar-search-for-mobile').slideToggle();
  });

});
