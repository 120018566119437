document.addEventListener('turbolinks:load', () => {

  // Generic dismiss function
  function setupDismissibleElements() {
    // Listen for clicks on elements with the class 'dismiss-button'
    $('.dismiss-button').on('click', function(event) {
      event.preventDefault();
      // Find the closest parent with the class 'dismissible-container' and hide it
      $(this).closest('.dismissible-container').fadeOut('fast');
    });
  }

  // Initialize dismiss functionality on page load
  setupDismissibleElements();

  ///////////////////////////////////////////////

  // Handle the click event on the "..." menu for each collection link
  $('.link-actions-button').on('click', function(event) {
    event.preventDefault();

    // Get the index of the clicked button
    const index = $(this).data('index');

    // Hide all other link actions dropdowns
    $('.link-actions-dropdown').not(`#link-actions-dropdown-${index}`).addClass('hidden');

    // Toggle the visibility of the corresponding link actions dropdown
    $(`#link-actions-dropdown-${index}`).toggleClass('hidden');
  });

  // Hide the dropdown when clicking outside of it
  $(document).on('click', function(event) {
    if (!$(event.target).closest('.link-actions-button, .link-actions-dropdown').length) {
      $('.link-actions-dropdown').addClass('hidden');
    }
  });

  ///////////////////////////////////////////////

  // Handle the copy URL functionality
  $('.copy-url').on('click', function(e) {
    e.preventDefault();

    // Get the URL from the data-url attribute
    var urlToCopy = $(this).data('url');

    // Create a temporary text area element
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val(urlToCopy).select();

    // Copy the URL to the clipboard
    document.execCommand("copy");

    // Remove the temporary element
    $temp.remove();

    // Close the dropdown menu after copying the URL
    $(this).closest('.link-actions-dropdown').addClass('hidden');

    // Show a success message using toastr (optional)
    toastr.success('URL copied to clipboard');
  });

  ///////////////////////////////////////////////

  // Handle toggling which collection link you want to see the sublinks for
  $('.selector-for-sublinks').on('click', function() {
    // Get the id of the collection link for which to load the sublinks
    const selectedLinkId = $(this).data('id');

    // Show the skeleton loader directly in JavaScript
    // I got this from https://tailwindcss.com/docs/animation#pulse
    const loaderHtml = `
      <div class="animate-pulse flex space-x-4">
        <div class="rounded-full bg-slate-200 h-10 w-10"></div>
        <div class="flex-1 space-y-6 py-1">
          <div class="h-2 bg-slate-200 rounded"></div>
          <div class="space-y-3">
            <div class="grid grid-cols-3 gap-4">
              <div class="h-2 bg-slate-200 rounded col-span-2"></div>
              <div class="h-2 bg-slate-200 rounded col-span-1"></div>
            </div>
            <div class="h-2 bg-slate-200 rounded"></div>
          </div>
        </div>
      </div>
    `;

    // Insert the loader into the container
    $('.profile-sublinks-container').html(loaderHtml);

    $.ajax({
      url: window.location.pathname,
      type: 'GET',
      dataType: 'script',
      data: { selected_link_id: selectedLinkId },
    });
  });

});