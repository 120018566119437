import { confirmEmailSubscription } from './email_subscription';

const form_validations = function() {
  function isChildOfAuthForm(element){
    return $(element).parents('.auth-form').length
  }

  function isChildOfBadLinkForm(element){
    return $(element).parents('.radio-buttons-group').length;
  }

  function validateUser(formObject){
    if (formObject.length == 0) return;

    if (formObject?.valid()) {
      formObject.submit();
    }else{
      const submitBtn = $(this);
      setTimeout(function () {
        submitBtn.removeAttr("disabled");
        submitBtn.html(submitBtn.attr("data-text"));
      }, 500);
    }
  }

  $.validator.setDefaults({
    highlight: function(element) {
      if(isChildOfAuthForm(element)) {
        const parent = $(element).parents(".input-group");
        if (parent.hasClass("valid")) {
          parent.removeClass("valid");
        }
        parent.addClass("invalid");
      }else{
        $(element).addClass('is-invalid');
      }
    },
    unhighlight: function(element) {
      if(isChildOfAuthForm(element)) {
        const parent = $(element).parents(".input-group");
        if (parent.hasClass("invalid")) {
          parent.removeClass("invalid");
        }
        parent.addClass("valid");
      }else{
        $(element).removeClass('is-invalid');
        $(element).addClass('is-valid');
      }
    },
    errorElement: 'span',
    errorClass: 'invalid-feedback',
    validClass: 'valid-feedback',
    errorPlacement: function(error, element) {
      const inputGroupParent = $(element).parents('.input-group');
      if(inputGroupParent.length) {
        error.insertAfter(inputGroupParent[0]);
      } else {
        error.insertAfter(element);
      }
    }
  });

  var response;
  var message_text = '';
  const username = $("#edit-user").val();
  $.validator.addMethod(
    "validUserName",
    function (value, element) {
      if (username === value) return true;

      $.ajax({
        type: "POST",
        url: location.origin + "/validate_username",
        async: false,
        data: { username: value },
        success: function (status) {
          response = status;
        },
        fail: function () {
          return true;
        },
      });

      if (!response.exists) {
        message_text = 'Username is already taken'
        return false
      }
      else if (response.blacklisted) {
        message_text = 'This username is unavailable.'
        return false
      }
      return true
  }, function() { return message_text } );

  $.validator.addMethod("alphanumeric", function(value, element) {
    return this.optional(element) || /^[a-zA-Z0-9_]+$/.test(value);
  });

  $.validator.addMethod("validEmail", function(value, element) {
    return this.optional(element) || /^[^@\s]+@[^@\s]+\.[^@\s]{2,}$/.test(value);
  });

  $.validator.addMethod("notBlank", function(value, element) {
    return this.optional(element) || value.trim().length > 0;
  }, "Please enter a valid url");

  $.validator.addMethod("confirmUrlSource", function() {
    return $('input[name="confirmUrl"]:checked').length > 0;
  }, "Please select one option");

  $.validator.addMethod("minCharacters", function(value, element) {
    return this.optional(element) || value.trim().length >= 3;
  });

  $('#new_user').validate({

    onkeyup: function(element) {
      $(element).valid();
    },

    rules: {
      'user[username]': {
        required: true,
        alphanumeric: true,
        validUserName: true,
        minCharacters: true,
      },

      'user[email]': {
        required: true,
        validEmail: true,
        maxlength: 255,
      },

      'user[password]': {
        required: true,
        minlength: 6,
      },
      'user[password_confirmation]': {
        required: true,
        minlength: 6,
      }
    },

    messages: {
      'user[username]': {
        alphanumeric: "Please only use numbers, letters, and underscores",
        minCharacters: 'Username must be at least 3 characters long.'
      },
      'user[email]': {
        validEmail: 'Please enter a valid email address.'
      }
    },
  });

  $('.auth-form-submit').on('click', (function() {
    validateUser($('#new_user'))
  }));

  $('#new_bad_link').validate({
    ignore: '.ignore',
    onkeyup: function (element) {
      $(element).valid();
    },
    rules: {
      'bad_link[url]': {
        required: true,
        notBlank: true,
      },
      'confirmUrl': {
        confirmUrlSource: true,
      }
    },
    highlight: function(element) {
      if(isChildOfBadLinkForm(element)) {
        $('input[name="confirmUrl"]').addClass('is-invalid');
        $('input[name="confirmUrl"]').css('box-shadow', 'none');
      }else{
        $(element).addClass('is-invalid');
      }
    },
    unhighlight: function(element) {
      if(isChildOfBadLinkForm(element)) {
        $('input[name="confirmUrl"]').removeClass('is-invalid');
      } else {
        $('#bad-link-errors').remove();
        $(element).removeClass('is-invalid');
        $(element).addClass('is-valid');
      }
    },
    errorPlacement: function(error, element) {
      const inputGroupParent = $(element).parents('.radio-buttons-group');
      if(inputGroupParent.length){
        error.insertAfter(inputGroupParent);
      } else{
        error.insertAfter(element);
      }
    }
  });

  $('#bad-link-submit').on('click',function (e) {
    if ($('#new_bad_link').valid()) {
      $('#new_bad_link').submit();
    }
  });

  $('#email-subscription-form').validate({
    onkeyup: function (element) {
      $(element).valid();
    },
    rules: {
      'email': {
        required: true,
        validEmail: true,
      },
    },
    messages: {
      'email': {
        validEmail: 'Please enter a valid email address.',
      },
    },
  });

  const emailSubscriptionForm = $('#email-subscription-form');
  $('#email-subsciption-btn').click(function () {
    if (emailSubscriptionForm.valid()) {
      confirmEmailSubscription(emailSubscriptionForm);
    }
  });
};

$(document).ready(form_validations);
$(document).on('turbolinks:load', form_validations);
$(document).ready(function() {
  $("#user_username").focus();
});
