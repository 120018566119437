// app/javascript/packs/truncate_text.js

$(document).ready(function () {
  const elements = document.querySelectorAll('.truncate-text');

  elements.forEach(function (element) {
    // Get the max length from the data attribute or default to 150
    const maxLength = element.dataset.maxLength ? parseInt(element.dataset.maxLength, 10) : 150;
    const fullText = element.textContent;

    if (fullText.length > maxLength) {
      const truncatedText = fullText.substring(0, maxLength) + '... '; // Add ellipsis
      const readMoreLink = document.createElement('a');
      readMoreLink.textContent = 'read more';
      readMoreLink.href = '#'; // You can set the link destination here
      readMoreLink.classList.add('read-more-link'); // Add the class

      readMoreLink.addEventListener('click', function (e) {
        e.preventDefault();
        element.textContent = fullText;
      });

      element.textContent = truncatedText;
      element.appendChild(readMoreLink);
    }
  });
});
